.form {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 90vw;
    padding: 10px;
    border-radius: 10px;
    background: #ffffff;
}

.title {
    text-align: center;
    padding-bottom: 10px;
}

.submit {
    left: 50%;
    transform: translateX(-50%);
}